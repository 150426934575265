import React, { useContext } from 'react';
import { GlobalDataContext } from '../../context/context';
import { BsFillInfoSquareFill } from "react-icons/bs";
import { BsChevronCompactDown } from "react-icons/bs";


const BlockOne = () => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
      <>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div className="">
            <img className='w-full h-full' src={rpdata?.gallery?.length > 20 ? rpdata?.gallery?.[14] : rpdata?.stock?.[2]} alt="not_found" />
          </div>
          <div className="items-center justify-center flex flex-col">
          <h2 className='text-center'>{rpdata?.dbSlogan?.[9]?.slogan}</h2>
            <div class="m-2 space-y-2 py-8">
              <div
                className="group flex flex-col gap-2 rounded-lg bg2 p-5 text-white"
                tabindex="1"
              >
                <div className="flex cursor-pointer items-center justify-between">
                  <span className='text-white font-bold'> <BsFillInfoSquareFill />{rpdata?.dbValues?.[0].title}</span>
                  <BsChevronCompactDown className='transition-all duration-500 group-focus:-rotate-180'/>
                </div>
                <div className="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
                  {rpdata?.dbValues?.[0].description}
                </div>
              </div>

              <div
                className="group flex flex-col gap-2 rounded-lg bg2 p-5 text-white"
                tabindex="1"
              >
                <div className="flex cursor-pointer items-center justify-between">
                  <span className='text-white font-bold'> <BsFillInfoSquareFill /> {rpdata?.dbValues?.[1].title} </span>
                  <BsChevronCompactDown className='transition-all duration-500 group-focus:-rotate-180'/>
                </div>
                <div className="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
                {rpdata?.dbValues?.[1].description}
                </div>
              </div>

              <div
                className="group flex flex-col gap-2 rounded-lg bg2 p-5 text-white"
                tabindex="3"
              >
                <div className="flex cursor-pointer items-center justify-between">
                  <span className='text-white font-bold'> <BsFillInfoSquareFill /> {rpdata?.dbValues?.[2].title} </span>
                  {/* <img
                    src="https://upload.wikimedia.org/wikipedia/commons/9/96/Chevron-icon-drop-down-menu-WHITE.png"
                    className="h-2 w-3 transition-all duration-500 group-focus:-rotate-180"
                    alt=""
                  /> */}
                  <BsChevronCompactDown className='transition-all duration-500 group-focus:-rotate-180'/>
                </div>
                <div className="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
                {rpdata?.dbValues?.[2].description}
                </div>
              </div>


            </div>
          </div>
        </div>
      </>
    );
}


export default BlockOne;